import React, { useState } from 'react';
import styled from 'styled-components';
import { parse } from 'json2csv';

import { useUserContext } from '../../context/UserContext';
import Button from '../Button';
import Loader from '../Loader';

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const ExportCustomersAsCsv = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { exportCustomers } = useUserContext();

  const onSubmit = async (e) => {
    e.preventDefault()
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const response = await exportCustomers();

      var link = document.createElement('a');
      link.setAttribute('href', response.data);
      link.setAttribute('download', 'customers.csv');
      document.body.appendChild(link);

      link.click();
    } catch (error) {
      setError(error.message);
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <>
      <form>
        <h2>Export Customers as CSV</h2>

        <Button disabled={loading} onClick={onSubmit}>
          Export
        </Button>

        {loading && <Loader />}

        {error && <ErrorText>ERROR: {error}</ErrorText>}
      </form>
    </>
  );
};

export default ExportCustomersAsCsv;

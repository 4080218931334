import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import styled from 'styled-components';

import { useUserContext } from '../../context/UserContext';
import Button from '../Button';
import TextInput from '../TextInput';
import Loader from '../Loader';

const ErrorText = styled.p`
  font-size: ${({ theme }) => theme.sizes.lvl2};
  color: ${({ theme }) => theme.colours.signal.error};
  margin: 0 ${({ theme }) => theme.sizes.lvl1};
`;

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required')
    .trim()
    .label('First name'),
  lastName: yup
    .string()
    .required('Last name is required')
    .trim()
    .label('Last name'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Must be at least 6 characters long')
    .label('Your password'),
  email: yup
    .string()
    .required('Email is required')
    .email('Must be a valid email')
    .trim()
    .label('Your email'),
});

const CreatePaidCustomerForm = () => {
  const [loading, setLoading] = useState(false);
  const [createdCustomer, setCreatedCustomer] = useState();
  const { createPaidCustomer } = useUserContext();
  const defaultValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  };
  const { register, handleSubmit, errors, setError, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(defaultValues);
  }, []);

  const onSubmit = async ({ email, password, firstName, lastName }) => {
    setCreatedCustomer(undefined);
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      const { data: customer } = await createPaidCustomer({
        email,
        password,
        firstName,
        lastName,
      });
      if (customer) setCreatedCustomer(customer);
    } catch (error) {
      console.log(error);
      setError('form', { message: error.message });
    }

    setLoading(false);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2>Create Paid Customer</h2>
        <TextInput
          name="firstName"
          placeholder="First name"
          ref={register}
          aria-label="First Name"
          error={errors.firstName?.message}
        />
        <TextInput
          name="lastName"
          placeholder="Last name"
          ref={register}
          aria-label="Last Name"
          error={errors.lastName?.message}
        />
        <TextInput
          name="email"
          placeholder="Email address"
          aria-label="Email address"
          ref={register}
          error={errors.email?.message}
        />
        <TextInput
          name="password"
          placeholder="Password"
          aria-label="Password"
          type="password"
          ref={register}
          error={errors.password?.message}
        />

        <Button type="submit" disabled={loading}>
          Create
        </Button>

        {loading && <Loader />}

        {errors && errors.form && (
          <ErrorText>ERROR: {errors.form?.message}</ErrorText>
        )}

        {createdCustomer && (
          <span>
            Paid customer successfully created {createdCustomer.email}
          </span>
        )}
      </form>
    </>
  );
};

export default CreatePaidCustomerForm;

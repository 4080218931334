import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import SalesLayout from '../layout/SalesLayout';
import { useUserContext } from '../context/UserContext';
import Seo from '../components/Seo';
import Button from '../components/Button';
import Container from '../layout/Container';
import CreatePaidCustomerForm from '../components/administrator/createPaidCustomerForm';
import DeletePaidCustomerForm from '../components/administrator/deletePaidCustomerForm';
import ExportCustomersAsCsvForm from '../components/administrator/exportCustomersAsCsvForm';

const PositionedButton = styled(Button)`
  margin-top: ${({ theme }) => theme.sizes.lvl2};
`;

const AdminPage = ({ location }) => {
  const { isAdmin, isLoggedIn, signOut } = useUserContext();

  let inner;

  if (!isAdmin) {
    inner = (
      <>
        <p>You must be logged in as an administrator to view this page.</p>
        <Link to="/">Homepage</Link>
        <br />
        {isLoggedIn ? (
          <PositionedButton onClick={signOut}>Logout</PositionedButton>
        ) : (
          <Link to="/login">Login</Link>
        )}
      </>
    );
  } else {
    inner = (
      <>
        <h1>Administrator Controls</h1>
        <CreatePaidCustomerForm />
        <DeletePaidCustomerForm />
        <ExportCustomersAsCsvForm />
      </>
    );
  }

  return (
    <SalesLayout location={location}>
      <Seo title="Admin" />
      <Container>{inner}</Container>
    </SalesLayout>
  );
};

export default AdminPage;
